export const AddMarkUpApi = [
  {
    id: 1,
    name: "airline",
    type: "text",
    placeholder: "Enter airline",
    label: "Airline",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  // {
  //   id: 2,
  //   name: "origin",
  //   type: "text",
  //   placeholder: "Enter origin",
  //   label: "Origin",
  //   required: "true",
  //   disabled: false,
  //   defaultValue: "",
  // },
  // {
  //   id: 3,
  //   name: "destination",
  //   type: "text",
  //   placeholder: "Enter destination",
  //   label: "Destination",
  //   required: "true",
  //   disabled: false,
  //   defaultValue: "",
  // },
  {
    id: 4,
    name: "amount",
    type: "text",
    placeholder: "Enter amount",
    label: "Amount",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  //   {
  //     id: 5,
  //     name: "markupType",
  //     type: "text",
  //     placeholder: "Enter markupType",
  //     label: "MarkupType",
  //     required: "true",
  //     disabled: false,
  //     defaultValue: "",
  //   },
  {
    id: 5,
    name: "source",
    type: "text",
    placeholder: "Enter source",
    label: "Source",
    required: "true",
    disabled: true,
    defaultValue: "GOOGLE",
  },
  {
    id: 6,
    name: "currency",
    type: "text",
    placeholder: "Enter currency",
    label: "Currency",
    required: "true",
    disabled: true,
    defaultValue: "INR",
  },
  {
    id: 7,
    name: "siteUrl",
    type: "text",
    placeholder: "Enter siteUrl",
    label: "SiteUrl",
    required: "true",
    disabled: true,
    defaultValue: "get.travomint.com",
  },
  {
    id: 8,
    name: "convenienceFee",
    type: "text",
    placeholder: "Convenience Fee",
    label: "Convenience Fee",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
];
