import React from "react";
import Inputs from "../../CustomComponent/Inputs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "../../CustomComponent/Select";
import Button from "../../CustomComponent/Button";
import MarkupType from "../../SmallApi/MarkupType.json";
import AmountType from "../../SmallApi/AmountType.json";
import { UpdateMarkUpApi } from "../../SmallApi/UpdateMarkUpApi";
import { useState } from "react";

const UpdateModalBody = ({ changeFunction, handleSubmit, selectedData }) => {
  return (
    <Row>
      {UpdateMarkUpApi({ data: selectedData }).map((item, i) => {
        return (
          <Col xs={12} md={6} xl={4} className="mb-4">
            <Inputs
              name={item.name}
              placeholder={item.placeholder}
              type={item.type}
              label={item.label}
              onChange={changeFunction}
              required={item.required}
              defaultValue={item.defaultValue}
              disabled={item.disabled}
              classstyle="form-control"
            />
          </Col>
        );
      })}

      <Col xs={12} md={6} xl={4} className="mb-4">
        <Select
          name="markupType"
          label="MarkupType"
          onChange={changeFunction}
          optionValue={MarkupType}
          required={true}
          defaultValue={selectedData.markupType}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Select
          name="amountType"
          label="Amount Type"
          onChange={changeFunction}
          optionValue={AmountType}
          required={true}
          defaultValue={selectedData.amountType}
          classstyle="form-control"
        />
      </Col>

      <div className="col-12 text-right border-top mt-1 py-4">
        <Button
          type="submit"
          title="Update"
          color="btn btn btn btn-submit btnver-2 "
          onClick={handleSubmit}
        />
      </div>
    </Row>
  );
};

export default UpdateModalBody;
