import axios from "axios";
import { UpdateMarkUp_Url } from "../Urls";
export const UpdateMarkUp = ({
  data,
  loading,
  state1,
  state2,
  navigate,
  state3,
}) => {
  const options = {
    method: "PUT",
    url: UpdateMarkUp_Url,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
    data: data,
  };

  loading(true);

  axios
    .request(options)
    .then(function (response) {
      if (
        response.data.httpStatus === "OK" &&
        response.data.data !== null &&
        response.data.msg.includes("successfully")
      ) {
        state1(response.data);
        loading(false);
        state2(false);
      } else {
        alert(response.data.msg);
        state1(response.data);
        loading(false);
        state2(false);
      }
    })
    .catch(function (error) {
      alert(error.response.data.msg);
      if (error.response.status == 401) {
        navigate("/login");
        localStorage.clear();
      }
    });
};
