import React from "react";
import { useState } from "react";
import { MarkUpStatus } from "../../../Apis/Markup/MarkUpStatus";
import Button from "../../../CustomComponent/Button";
import Icons from "../../../CustomComponent/Icons";
import Modals from "../../../CustomComponent/Modals";
import Table from "../../../CustomComponent/Table";
import AddMarkUpModalBody from "../AddMarkUpModalBody";
import UpdateModalBody from "../UpdateModalBody";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const ResultMarkupDetails = ({
  setShowAddModal,
  getMarkupList,
  UpdateOpenModal,
  showModal,
  closeModal,
  UpdateMarkUpDataByInput,
  UpdateMarkUpDataSubmit,
  selectedMarkupdata,
  showAddModal,
  addCloseModal,
  AddMarkUpDataByInput,
  AddMarkUpDataSubmit,
  UpdateStatusMarkup,
}) => {
  return (
    <div className="w-100  ">
      <div className=" mb-3 btn-dark-add">
        <Button
          title={<><i className="fa-solid fa-plus icon-add"></i> Add</>}
          color="btn-submit btnver-2 success"
          onClick={() => setShowAddModal(true)}
        />
      </div>

      <div className="metaBox position-relative d-block border-0 smtb">
        <div className="table-responsive lightthm-wrap scroll-auto">
          <table className="table table-lightthm text-left">
            <thead className="bg-light">
              <tr>
                <th scope="col" width={30}>
                  Markup ID
                </th>
                <th scope="col">Airline</th>
                {/* <th scope="col">Origin</th> */}
                {/* <th scope="col">Destination</th> */}
                <th scope="col">Amount</th>
                <th scope="col">Amount Type</th>
                <th scope="col">MarkupType</th>
                <th scope="col">Source</th>
                <th scope="col">Currency</th>
                <th scope="col">SiteUrl</th>
                <th scope="col">Convenience Fee</th>
                <th scope="col">Update</th>
                <th scope="col">Expired</th>
              </tr>
            </thead>
            <tbody>
              {getMarkupList.data.map((item, i) => {
                const editbtn = i !== 0 && (  
                  <div className="btni-sp">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Update</Tooltip>}>
                      <button
                        className="btn btn-sm btn-soft-success"
                        onClick={() => UpdateOpenModal(item)}
                      >
                        <Icons iconName="fa fa-pencil" />
                      </button>
                    </OverlayTrigger>
                  </div>
                );
                const expire = i !== 0 && (
                  <input
                    onClick={() => UpdateStatusMarkup(item)}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={item.expired}
                    classstyle="form-control"
                  />
                );
                return (
                  <>
                    <Table
                      data={[
                        item.markupId,
                        item.airline,
                        // item.origin,
                        // item.destination,
                        item.amount,
                        item.amountType,
                        item.markupType,
                        item.source,
                        item.currency,
                        item.siteUrl,
                        item.convenienceFee,
                        // item.modifiedDate,
                        editbtn,
                        <div className="form-check form-switch">{expire}</div>,
                      ]}
                    />
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modals
        show={showModal}
        HandleClose={closeModal}
        ModalBody={
          <UpdateModalBody
            changeFunction={UpdateMarkUpDataByInput}
            handleSubmit={UpdateMarkUpDataSubmit}
            selectedData={selectedMarkupdata}
          />
        }
        title="Update Markup"
      />
      <Modals
        show={showAddModal}
        HandleClose={addCloseModal}
        ModalBody={
          <AddMarkUpModalBody
            changeFunction={AddMarkUpDataByInput}
            handleSubmit={AddMarkUpDataSubmit}
          />
        }
        title="Add Markup"
      />
    </div>
  );
};

export default ResultMarkupDetails;
