import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { MarkUpList } from "../Apis/Markup/MarkUpList";
import UserBar from "../Molecule/User/UserBar";
import { AddMarkUp } from "../Apis/Markup/AddMarkUp";
import { useNavigate } from "react-router-dom";
import { UpdateMarkUp } from "../Apis/Markup/UpdateMarkUp";
import MarkupNotFound from "../Molecule/MarkUpDeatils/ErrorScreen/MarkupNotFound";
import ResultNotFound from "../Molecule/MarkUpDeatils/ErrorScreen/ResultNotFound";
import ResultMarkupDetails from "../Molecule/MarkUpDeatils/ResultScreen/ResultMarkupDetails";
import { MarkUpStatus } from "../Apis/Markup/MarkUpStatus";
import Loading from "../CustomComponent/Loading";
import Screen from "../CustomComponent/Screen";

const MarkUpDetails = () => {
  const navigate = useNavigate();
  const [selectedMarkupdata, setSelectedMarkupdata] = useState({});
  const [getMarkupList, setGetMarkupList] = useState({});
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addMarkUpData, setAddMarkUpData] = useState({
    airline: "",
    origin: "",
    destination: "",
    amount: 0,
    markupType: "",
    convenienceFee: "",
    source: "GOOGLE",
    currency: "INR",
    siteUrl: "get.travomint.com",
  });

  const [addMarkupDataGetValue, setAddMarkupDataGetValue] = useState({});
  const [addDataLoading, setAddDataLoading] = useState(false);
  //Update MarkUp
  const [updateDataLoading, setUpdateDataLoading] = useState(false);
  const [updateData, setUpdateData] = useState({});
  //MarkupStatus
  const [markupStatus, setMarkupStatus] = useState(false);
  useEffect(() => {
    MarkUpList({
      state: setGetMarkupList,
      loading: setLoading,
      navigate,
    });
  }, [addDataLoading, updateDataLoading, markupStatus]);

  const closeModal = () => {
    setShowModal(false);
  };

  const addCloseModal = () => {
    setShowAddModal(false);
  };

  const AddMarkUpDataByInput = (e) => {
    const { name, value } = e.target;
    setAddMarkUpData({ ...addMarkUpData, [name]: value });
  };

  const UpdateMarkUpDataByInput = (e) => {
    const { name, value } = e.target;
    setSelectedMarkupdata({ ...selectedMarkupdata, [name]: value });
  };

  const AddMarkUpDataSubmit = () => {
    if (
      addMarkUpData.airline != "" &&
      addMarkUpData.amount != "" &&
      addMarkUpData.markupType != "" &&
      addMarkUpData.source != "" &&
      addMarkUpData.currency != "" &&
      addMarkUpData.siteUrl != ""
    ) {
      AddMarkUp({
        data: addMarkUpData,
        state1: setAddMarkupDataGetValue,
        modalState: setShowAddModal,
        loading: setAddDataLoading,
        navigate,
      });
    } else {
      alert("Please Fill All Input Box");
    }
  };
  const UpdateMarkUpDataSubmit = () => {
    UpdateMarkUp({
      data: selectedMarkupdata,
      loading: setUpdateDataLoading,
      state1: setUpdateData,
      state2: setShowModal,
      navigate,
    });
  };
  const UpdateOpenModal = (item) => {
    setSelectedMarkupdata(item);
    setShowModal(true);
  };

  const UpdateStatusMarkup = (item) => {
    if (item.expired == true) {
      MarkUpStatus({ id: item._id, status: false, loading: setMarkupStatus });
    } else {
      MarkUpStatus({ id: item._id, status: true, loading: setMarkupStatus });
    }
  };

  return (
    <div className="position-relative">
      {/* <UserBar Pagetitle={`Markup Details`}/> */}
      {(loading || markupStatus || addDataLoading) && (
        <Loading title="loading..." />
      )}
      <Screen>
        {Object.keys(getMarkupList).length !== 0 ? (
          <>
            {getMarkupList.httpStatus != "NOT_FOUND" &&
            getMarkupList.data != null &&
            getMarkupList.data.length > 0 ? (
              <ResultMarkupDetails
                setShowAddModal={setShowAddModal}
                getMarkupList={getMarkupList}
                UpdateOpenModal={UpdateOpenModal}
                showModal={showModal}
                closeModal={closeModal}
                UpdateMarkUpDataByInput={UpdateMarkUpDataByInput}
                UpdateMarkUpDataSubmit={UpdateMarkUpDataSubmit}
                selectedMarkupdata={selectedMarkupdata}
                showAddModal={showAddModal}
                addCloseModal={addCloseModal}
                AddMarkUpDataByInput={AddMarkUpDataByInput}
                AddMarkUpDataSubmit={AddMarkUpDataSubmit}
                UpdateStatusMarkup={UpdateStatusMarkup}
              />
            ) : (
              <MarkupNotFound
                setShowAddModal={setShowAddModal}
                showAddModal={showAddModal}
                addCloseModal={addCloseModal}
                AddMarkUpDataByInput={AddMarkUpDataByInput}
                AddMarkUpDataSubmit={AddMarkUpDataSubmit}
              />
            )}
          </>
        ) : (
          <ResultNotFound setShowAddModal={setShowAddModal} />
        )}
      </Screen>
    </div>
  );
};

export default MarkUpDetails;
