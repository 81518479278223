import React, { useEffect, useState } from "react";
import Loading from "../../../CustomComponent/Loading";
import loader from "../../../Image/93134-not-found.json";
import Lottie from "react-lottie";
import Modals from "../../../CustomComponent/Modals";
import AddPaymentGateway, { allProducts } from "./Modal/AddPaymentGateway";
import {
  deleteGatewayApi,
  getAllGatewayListApi,
  getAllPaymentGateListApi,
} from "../../../Services/Api/paymentGateway";
import Table from "../../../CustomComponent/Table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "../../../CustomComponent/Select";
import Inputs from "../../../CustomComponent/Inputs";
const PaymentGateway = ({ allSource }) => {
  const [allResult, setAllResult] = useState([]);
  const [isEditdata, setIsEdit] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [gatewayData, setGateWayData] = useState([]);
  const [allGatewayList, setAllGatewayList] = useState([]);
  const [filterData, setfilterData] = useState({
    source: "",
    product: "",
    siteId: "",
    currency: "",
    gateWay: "",
  });
  const { source, product, gateWay, siteId, currency } = filterData;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const fetchData = async () => {
    setfilterData({
      source: "",
      product: "",
      siteId: "",
      currency: "",
      gateWay: "",
    });
    try {
      const { data } = await getAllGatewayListApi();
      if (data.data) {
        setAllResult(data.data);
        setGateWayData(data.data);
      } else {
        setAllResult([]);
        setGateWayData([]);
      }
    } catch (error) {}
    setLoading(false);
  };
  const fetchAllPaymentGate = async () => {
    try {
      const { data } = await getAllPaymentGateListApi();
      data.data ? setAllGatewayList(data.data) : setAllGatewayList([]);
    } catch (error) {}
    // setLoading(false);
  };
  // ----------------------------------------------Delete API CALL------------------------------------------

  const handleDeleteApi = async (id) => {
    try {
      const { data } = await deleteGatewayApi(id);
      data.httpStatus === "OK" && fetchData();
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
  };
  // ---------------------------------Handle Filter-----------------
  const handleFilter = (e) => {
    let { name, value } = e.target;
    setfilterData({ ...filterData, [name]: value });
  };
  useEffect(() => {
    fetchData();
    fetchAllPaymentGate();
  }, []);
  // ------------------------Filter Logic-----------------
  useEffect(() => {
    let newRes = [...allResult];
    if (source) {
      newRes = newRes.filter((it) => it.sourceMedia === source);
    }
    if (gateWay) {
      newRes = newRes.filter((it) => it.gatewayName === gateWay);
    }
    if (siteId) {
      newRes = newRes.filter((it) => it.siteId === +siteId);
    }
    if (product) {
      newRes = newRes.filter((it) => it.product === product);
    }
    if (currency) {
      newRes = newRes.filter((it) => it.currency === currency);
    }
    setGateWayData(newRes);
  }, [filterData]);

  return (
    <div className="w-100">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {loading && <Loading title="loading..." />}
          {gatewayData.length <= 0 && allResult.length <= 0 ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6> Click on add button to add Gateway data</h6>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="table-responsive lightthm-wrap  border-top-0">
                  <table className="table table-lightthm text-left">
                    <thead className="bg-light">
                      <tr>
                        <th width={20}>S.no.</th>
                        <th className="">
                          <Select
                            label=""
                            firstName="Select Source"
                            name="source"
                            value={source}
                            optionValue={allSource.data}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{ width: "150px" }}
                          />
                        </th>
                        <th>
                          <Select
                            label=""
                            name="gateWay"
                            value={gateWay}
                            firstName="Payment Gateway"
                            optionValue={allGatewayList}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{ width: "150px" }}
                          />
                        </th>
                        <th>Charge Type</th>
                        <th>Charge Value</th>
                        <th>
                          <Inputs
                            name="siteId"
                            value={siteId}
                            placeholder={"Site ID"}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{
                              width: "80px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                        <th>
                          <Select
                            name="product"
                            value={product}
                            firstName="Select Product"
                            optionValue={allProducts}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{ width: "150px" }}
                          />
                        </th>
                        <th>
                          <Inputs
                            name="currency"
                            value={currency}
                            placeholder={"Currency"}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{
                              width: "100px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                        <th>Field String</th>
                        <th>Order Sequence</th>
                        <th>Status</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gatewayData.map((item, i) => (
                        <Table
                          i={i}
                          data={[
                            i + 1,
                            item.sourceMedia,
                            item.gatewayName,
                            item.chargeType,
                            item.chargeValue,
                            item.siteId,
                            item.product,
                            item.currency,
                            item.fieldString,
                            item.orderSequence,
                            <div
                              className={`${
                                item.gatewayStatus
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                            >
                              <i
                                className={`fa-solid fa-circle`}
                                style={{
                                  fontSize: "10px",
                                  marginRight: "4px",
                                }}
                              ></i>
                              <span className="">
                                {item.gatewayStatus ? "Active" : "Inactive"}
                              </span>
                            </div>,
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Edit</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-success"
                                  onClick={() => {
                                    setIsEdit(item);
                                    setShowModal(true);
                                  }}
                                >
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-danger"
                                  onClick={() => handleDeleteApi(item._id)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                          ]}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="text-right px-3 py-4">
            <button
              className="btn-submit btnver-2 success"
              onClick={() => {
                setShowModal(true);
                setIsEdit("");
              }}
            >
              Add <i className="fa-solid fa-plus icon-add"></i>
            </button>
          </div>
        </div>
      </div>
      <Modals
        show={showModal}
        HandleClose={() => setShowModal(false)}
        ModalBody={
          <AddPaymentGateway
            allSource={allSource}
            fetchData={fetchData}
            setShowModal={setShowModal}
            isEditdata={isEditdata}
            allGatewayList={allGatewayList}
          />
        }
        title={isEditdata ? "Update Payment Gateway" : "Add Payment Gateway"}
      />
      {/* <Modals
        show={showEditModal}
        HandleClose={() => setShowEditModal(false)}
        ModalBody={
          <UpdateContentVisData
            allApi={allApi}
            allSource={allSource}
            fetchData={fetchData}
            setShowEditModal={setShowEditModal}
            editData={editData}
          />
        }
        title={"Update"}
      /> */}
    </div>
  );
};

export default PaymentGateway;
