import axios from "axios";
import { Update_Markup_Status } from "../Urls";
export const MarkUpStatus = ({ id, status, loading }) => {
  loading(true);
  const options = {
    method: "GET",
    url: `${Update_Markup_Status}?id=${id}&status=${status}`,
    headers: {
      "x-api-key": localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };

  axios
    .request(options)
    .then(function (response) {
      if (
        response.data.httpStatus === "OK" &&
        response.data.data !== null &&
        response.data.msg.includes("successfully")
      ) {
        loading(false);
      } else {
        alert(response.data.msg);
        loading(false);
      }
    })
    .catch(function (error) {
      console.error(error);
    });
};
