import axios from "axios";
import { AddMarkUp_Url } from "../Urls";
export const AddMarkUp = ({ data, loading, state1, modalState, navigate }) => {
  const options = {
    method: "POST",
    url: AddMarkUp_Url,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
    data: data,
  };
  loading(true);
  axios
    .request(options)
    .then(function (response) {
      if (
        response.data.httpStatus === "OK" &&
        response.data.data !== null &&
        response.data.msg.includes("successfully")
      ) {
        state1(response.data);
        modalState(false);
        loading(false);
      } else {
        alert(response.data.msg);
        state1(response.data);
        modalState(false);
        loading(false);
      }
    })
    .catch(function (error) {
      alert(error.response.data.msg);
      if (error.response.status == 401) {
        navigate("/login");
        localStorage.clear();
      }
    });
};
